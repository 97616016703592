import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import { Autocomplete } from '@mui/material';
import { useSnackbar } from '../../components/SnackbarProvider';
import { handleApiError, DecimalTextField, formatAutocompleteOption } from '../../components/Utils';
import DateTimePickerComponent from "../../components/DateTimePickerComponent";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import "dayjs/locale/es";
import {
    Button,
    TextField,
    Grid,
    Paper,
    Typography,
    CircularProgress,
    Box,
    FormControlLabel,
    Switch
} from "@mui/material";

dayjs.extend(utc);

function NewDeliveryNote() {
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [isOrder, setIsOrder] = useState(true);

    const [quarries, setQuarries] = useState([]);
    const [quarrySelected, setQuarrySelected] = useState(null);

    const [buildings, setBuildings] = useState([]);
    const [buildingSelected, setBuildingSelected] = useState(null);

    const [clients, setClients] = useState(null);
    const [clientSelected, setClientSelected] = useState(null);

    const [trucks, setTrucks] = useState([]);
    const [truckSelected, setTruckSelected] = useState(null);

    const [truckDrivers, setTruckDrivers] = useState([]);
    const [truckDriverSelected, setTruckDriverSelected] = useState(null);

    const [trailers, setTrailers] = useState([]);
    const [trailerSelected, setTrailerSelected] = useState(null);

    const [operators, setOperators] = useState([]);
    const [operatorSelected, setOperatorSelected] = useState(null);

    const [transporters, setTransporters] = useState([]);
    const [transporterSelected, setTransporterSelected] = useState(null);

    const [products, setProducts] = useState([]);
    const [productSelected, setProductSelected] = useState(null);

    const [orders, setOrders] = useState(null);
    const [orderSelected, setOrderSelected] = useState(null);
    const [orderDetails, setOrderDetails] = useState([]);
    const [orderDetailSelected, setOrderDetailSelected] = useState(null);

    const [deliveryNoteForm, setDeliveryNoteForm] = useState({
        quarry_id: null,
        building_id: null,
        truck_id: null,
        trailer_id: null,
        operator_id: null,
        client_id: null,
        truck_driver_id: null,
        order_detail_id: null,
        product_id: null,
        gross: null,
        departure_date: dayjs().local().format(),
        observations: null,
    });

    const fetchQuarries = useCallback(async () => {
        try {
            const result = await api().get('/quarries/');
            setQuarries(result.data[0]);
        } catch (error) {
            setError('Error obteniendo canteras.');
        }
    }, [api]);

    const fetchBuildings = useCallback(async () => {
        try {
            const params = {};
            params.client_id = clientSelected.id;

            const result = await api().get('/buildings/', { params });
            setBuildings(result.data[0]);

        } catch (error) {
            setError('Error obteniendo obras.');
        }
    }, [api, clientSelected]);

    const fetchClients = useCallback(async () => {
        try {
            const result = await api().get('/clients/');
            setClients(result.data[0]);

        } catch (error) {
            setError('Error obteniendo clientes.');
        }
    }, [api]);

    const fetchTrailers = useCallback(async () => {
        try {
            const result = await api().get('/trailers/');
            setTrailers(result.data[0]);

        } catch (error) {
            setError('Error obteniendo remolques.');
        }
    }, [api]);

    const fetchTransporters = useCallback(async () => {
        try {
            const result = await api().get('/transporters/');
            setTransporters(result.data[0]);

        } catch (error) {
            setError('Error obteniendo transportistas.');
        }
    }, [api]);

    const fetchOperators = useCallback(async () => {
        try {
            const result = await api().get('/operators/');
            setOperators(result.data[0]);

        } catch (error) {
            setError('Error obteniendo operarios.');
        }
    }, [api]);

    const fetchTrucks = useCallback(async () => {
        try {
            const params = {};
            params.transporter_id = transporterSelected.id;

            const result = await api().get('/trucks/', { params });
            setTrucks(result.data[0]);

        } catch (error) {
            setError('Error obteniendo camiones.');
        }
    }, [api, transporterSelected]);

    const fetchTruckDrivers = useCallback(async () => {
        try {

            const result = await api().get('/truck_drivers/');
            setTruckDrivers(result.data[0]);

        } catch (error) {
            setError('Error obteniendo camioneros.');
        }
    }, [api, clientSelected]);

    const fetchOrders = useCallback(async () => {
        try {
            const params = {};
            params.quarry_id = quarrySelected.id;
            params.client_id = clientSelected.id;

            const result = await api().get('/orders/get_client_quarry_orders', { params });
            setOrders(result.data);

        } catch (error) {
            setError('Error obteniendo pedidos.');
        }
    }, [api, quarrySelected, clientSelected]);

    const fetchProducts = useCallback(async () => {
        try {
            const params = {};
            params.quarry_id = quarrySelected.id;

            const result = await api().get('/products/get_products', { params });
            setProducts(result.data);

        } catch (error) {
            setError('Error obteniendo productos.');
        }
    }, [api, quarrySelected]);

    useEffect(() => {
        fetchQuarries();
        fetchClients();
        fetchTrailers();
        fetchTransporters();
        fetchOperators();
    }, []);

    useEffect(() => {
        if (quarrySelected) {
            fetchProducts();
        }
    }, [quarrySelected]);

    useEffect(() => {
        if (transporterSelected) {
            fetchTrucks();
            fetchTruckDrivers();
        }
    }, [transporterSelected]);

    useEffect(() => {
        if (clientSelected) {
            fetchBuildings();
        }
    }, [clientSelected]);

    useEffect(() => {
        if (clientSelected && quarrySelected) {
            fetchOrders();
        }
    }, [clientSelected, quarrySelected]);

    const handleChangeAutocomplete = (name, newValue) => {
        if (name === 'quarry_id') {
            setQuarrySelected(newValue);
            setOrderSelected(null);
            setOrderDetailSelected(null);
            setProductSelected(null);
        } else if (name === 'building_id') {
            setBuildingSelected(newValue);
        } else if (name === 'operator_id') {
            setOperatorSelected(newValue);
        } else if (name === 'transporter_id') {
            setTransporterSelected(newValue);
            setTruckSelected(null);
            setTrailerSelected(null);
            setTruckDriverSelected(null);
        } else if (name === 'client_id') {
            setClientSelected(newValue);
            setOrderSelected(null);
            setOrderDetailSelected(null);
        } else if (name === 'order_id') {
            setProductSelected(null);
            setOrderSelected(newValue);
            if (newValue?.order_details != null) {
                setOrderDetails(newValue.order_details);
                if (newValue.building) {
                    setBuildingSelected(newValue.building);
                    setDeliveryNoteForm(prevState => ({
                        ...prevState,
                        building_id: newValue.building.id
                    }));
                } else {
                    setBuildingSelected(null);
                }
            } else {
                setOrderDetails([]);
                setBuildingSelected(null);
            }
            return
        } else if (name === 'order_detail_id') {
            setOrderDetailSelected(newValue);
            if (newValue?.product != null) {
                setProductSelected(newValue.product);
                setDeliveryNoteForm(prevState => ({
                    ...prevState,
                    product_id: newValue.product.id
                }));
            } else {
                setProductSelected(null);
            }
        } else if (name === 'product_id') {
            setProductSelected(newValue);
        } else if (name === 'truck_id') {
            setTruckSelected(newValue);
            if (newValue?.trailer != null) {
                setTrailerSelected(newValue.trailer);
                setDeliveryNoteForm(prevState => ({
                    ...prevState,
                    trailer_id: newValue.trailer.id
                }));
            } else {
                setTrailerSelected(null);
                setDeliveryNoteForm(prevState => ({
                    ...prevState,
                    trailer_id: null
                }));
            }
            if (newValue?.truck_driver != null) {
                setTruckDriverSelected(newValue.truck_driver);
                setDeliveryNoteForm(prevState => ({
                    ...prevState,
                    truck_driver_id: newValue.truck_driver.id
                }));
            } else {
                setTruckDriverSelected(null);
                setDeliveryNoteForm(prevState => ({
                    ...prevState,
                    truck_driver_id: null
                }));
            }
        } else if (name === 'trailer_id') {
            setTrailerSelected(newValue);
        } else if (name === 'truck_driver_id') {
            setTruckDriverSelected(newValue);
        }
        setDeliveryNoteForm(prevState => ({
            ...prevState,
            [name]: newValue?.id
        }));
    };
    const createDeliveryNote = async (event) => {

        let error = null;

        try {
            event.preventDefault();
            setLoading(true);
            await api().post('/delivery_notes/', deliveryNoteForm);

            showSnackbar("Albarán creado correctamente");
            setError(null);
            navigate(`/delivery_notes/list`);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando albarán.')
        } finally {
            setError(prevError => ({ ...prevError, general: error }));
            setLoading(false);
        }
    };

    const handleDateTime = (e) => {
        const localDate = dayjs(e).local().format();
        setDeliveryNoteForm({ ...deliveryNoteForm, departure_date: localDate });
    };
    const handleChange = (event) => {
        const { name, value } = event.target;
        setDeliveryNoteForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSwitchChange = (event) => {
        const isActivated = event.target.checked;
        setIsOrder(isActivated);

        if (isActivated) {
            setBuildingSelected(null);
        } else {
            setBuildingSelected(null);
            setOrderSelected(null);
            setOrderDetailSelected(null);
        }
    };

    const canChangeBuilding = (clientSelected && !orderSelected) || (orderSelected && !orderSelected?.building);

    const maxNet = orderDetailSelected?.quantity - orderDetailSelected?.delivered_quantity;

    const tare = truckSelected?.tare;
    const net = deliveryNoteForm.gross - tare;

    const grossError = {
        isNegativeOrZero: net <= 0 ? "El neto no puede ser 0 o negativo" : null,
        exceedsStock: productSelected?.stock < net ? "El neto no puede superar el stock disponible" : null,
        exceedsOrderDetail: orderDetailSelected && net > maxNet ? "No puedes superar la cantidad del pedido" : null,
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading && (
                <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                    <Typography component="h2" variant="h5">
                        Nuevo albarán
                    </Typography>
                    <br />
                    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isOrder}
                                    onChange={handleSwitchChange}
                                    name="deliveryNoteType"
                                />
                            }
                            label="Albarán de pedido"
                        />
                    </Box>
                    <form onSubmit={createDeliveryNote}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={quarries}
                                    getOptionLabel={(option) => formatAutocompleteOption(option)}
                                    value={quarrySelected}
                                    onChange={(event, newValue) => handleChangeAutocomplete('quarry_id', newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cantera"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={transporters}
                                    getOptionLabel={(option) => formatAutocompleteOption(option)}
                                    value={transporterSelected}
                                    onChange={(event, newValue) => handleChangeAutocomplete('transporter_id', newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Transportista"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={operators}
                                    getOptionLabel={(option) => formatAutocompleteOption(option)}
                                    value={operatorSelected}
                                    onChange={(event, newValue) => handleChangeAutocomplete('operator_id', newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Op. Transporte"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={clients}
                                    getOptionLabel={(option) => formatAutocompleteOption(option)}
                                    value={clientSelected}
                                    onChange={(event, newValue) => handleChangeAutocomplete('client_id', newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Cliente"
                                            fullWidth
                                        />
                                    )}
                                />
                            </Grid>
                            {clientSelected && quarrySelected && isOrder && (
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={orders}
                                        getOptionLabel={(option) => option.code?.toString()}
                                        value={orderSelected}
                                        onChange={(event, newValue) => handleChangeAutocomplete('order_id', newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Pedido"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            {orderSelected && (
                                <>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={orderDetails.filter(detail => detail.product.quarry_id === quarrySelected.id)}
                                            getOptionLabel={(option) => option.code?.toString() + ' - ' + option.product_name?.toString()}
                                            value={orderDetailSelected}
                                            onChange={(event, newValue) => handleChangeAutocomplete('order_detail_id', newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Línea de Pedido"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                        {orderDetailSelected && (
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                style={{ marginTop: 3, marginBottom: 16 }}
                                            >
                                                Cantidad pedida: {orderDetailSelected.quantity} {orderDetailSelected.unit} <br />
                                                Cantidad entregada: {orderDetailSelected.delivered_quantity} {orderDetailSelected.unit} <br />
                                                Restante: {maxNet} {orderDetailSelected.unit}
                                            </Typography>
                                        )}
                                    </Grid>
                                </>
                            )}
                            {(orderSelected || clientSelected) && (
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={buildings}
                                        getOptionLabel={(option) => formatAutocompleteOption(option)}
                                        value={buildingSelected}
                                        disabled={!canChangeBuilding}
                                        onChange={(event, newValue) => handleChangeAutocomplete('building_id', newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Obra"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            {transporterSelected && (
                                <>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={trucks}
                                            getOptionLabel={(option) => (option.license_plate ? option.license_plate.toString() : "")}
                                            value={truckSelected}
                                            onChange={(event, newValue) => handleChangeAutocomplete('truck_id', newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Camión"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={truckDrivers}
                                            getOptionLabel={(option) => formatAutocompleteOption(option)}
                                            value={truckDriverSelected}
                                            onChange={(event, newValue) => handleChangeAutocomplete('truck_driver_id', newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Conductor"
                                                    fullWidth

                                                />
                                            )}
                                        />
                                    </Grid>
                                </>
                            )}
                            {truckSelected && truckSelected.trailer != null && (
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={trailers}
                                        getOptionLabel={(option) => (option.license_plate ? option.license_plate.toString() : "")}
                                        value={trailerSelected}
                                        onChange={(event, newValue) => handleChangeAutocomplete('trailer_id', newValue)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Remolque"
                                                fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                            {quarrySelected && (
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={products}
                                        getOptionLabel={(option) => option.product_group.name?.toString()}
                                        value={productSelected}
                                        onChange={(event, newValue) => handleChangeAutocomplete('product_id', newValue)}
                                        disabled={Boolean(orderSelected)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Producto"
                                                fullWidth
                                            />
                                        )}
                                    />
                                    {/* Mostrar stock solo si se ha seleccionado un producto */}
                                    {productSelected && productSelected.stock && (
                                        <>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                style={{ marginTop: 3, marginBottom: 16 }} // Añadido marginBottom para separación
                                            >
                                                Stock disponible: {productSelected.stock} {productSelected.product_group.unit}
                                            </Typography>


                                            {/* Añadir Grid container con espaciado entre campos */}
                                            <Grid container spacing={2}>
                                                {/* Campo Bruto (Gross) */}
                                                <Grid item xs={12}>
                                                    <DecimalTextField
                                                        label="Bruto"
                                                        name="gross"
                                                        value={deliveryNoteForm.gross}
                                                        onChange={handleChange}
                                                        error={Object.values(grossError).some(error => error !== null)}
                                                        helperText={Object.values(grossError).filter(error => error !== null).join(' ')}
                                                    />
                                                </Grid>

                                                {/* Campo Tara (readonly) */}
                                                <Grid item xs={12}>
                                                    <DecimalTextField
                                                        label="Tara"
                                                        name="tare"
                                                        value={tare || 0} // Si no hay camión seleccionado, muestra 0
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Grid>

                                                {/* Campo Neto (readonly) */}
                                                <Grid item xs={12}>
                                                    <DecimalTextField
                                                        label="Neto"
                                                        name="net"
                                                        value={net || 0} // Calcula neto automáticamente
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <DateTimePickerComponent
                                    required
                                    fullWidth
                                    label={"Fecha"}
                                    name="date"
                                    onChange={handleDateTime}
                                    value={deliveryNoteForm.departure_date}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Observaciones"
                                    multiline
                                    rows={4}
                                    name="observations"
                                    value={deliveryNoteForm.observations}
                                    onChange={handleChange}
                                />
                            </Grid>

                        </Grid>
                        <br />
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={
                                    loading ||
                                    !Object.values(grossError).every(error => error === null) ||
                                    !deliveryNoteForm.operator_id ||
                                    !deliveryNoteForm.transporter_id ||
                                    !deliveryNoteForm.client_id ||
                                    !deliveryNoteForm.quarry_id ||
                                    (!deliveryNoteForm.building_id && !deliveryNoteForm.order_detail_id) ||
                                    !deliveryNoteForm.truck_id ||
                                    !deliveryNoteForm.truck_driver_id ||
                                    !deliveryNoteForm.product_id ||
                                    !deliveryNoteForm.gross ||
                                    !deliveryNoteForm.departure_date
                                }>
                                Crear
                            </Button>
                        </Grid>
                    </form>
                    {error?.general && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {error.general}
                        </Typography>
                    )}
                </Paper>
            )}

        </div>
    );
}

export default NewDeliveryNote