import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import { useSnackbar } from '../../components/SnackbarProvider';
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress
} from "@mui/material";

function NewOperator() {

    const { api } = useAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const [operatorForm, setOperatorForm] = useState({
        name: null,
        email: null,
        phone: null,
        doc_type: null,
        documentation: null,
        country: null,
        zip_code: null,
        region: null,
        city: null,
        address: null,
        commercial_name: null,
    });

    const handleChangeForm = (event) => {
        const { name, value } = event.target;
        setOperatorForm(prevState => ({
            ...prevState,
            [name]: value
        }));
        setError(prevError => ({ ...prevError, [name]: null }));
    };

    const validateForm = () => {
        const errors = {};

        if (!operatorForm.name) {
            errors.name = "Por favor, añada un nombre.";
        }
        if (operatorForm.name && operatorForm.name.length > 250) {
            errors.name = "El nombre no debe exceder los 250 caracteres.";
        }
        if (operatorForm.email && operatorForm.email.length > 36) {
            errors.email = "El email no debe exceder los 36 caracteres.";
        }
        if (operatorForm.country && operatorForm.country.length > 36) {
            errors.country = "El país no debe exceder los 36 caracteres.";
        }
        if (operatorForm.phone && operatorForm.phone.length > 36) {
            errors.phone = "El teléfono no debe exceder los 36 caracteres.";
        }
        if (operatorForm.zip_code && operatorForm.zip_code.length > 40) {
            errors.zip_code = "El código postal no debe exceder los 40 caracteres.";
        }
        if (operatorForm.province && operatorForm.province.length > 150) {
            errors.province = "La provincia no debe exceder los 150 caracteres.";
        }
        if (operatorForm.address && operatorForm.address.length > 100) {
            errors.address = "La dirección no debe exceder los 100 caracteres.";
        }
        if (operatorForm.region && operatorForm.region.length > 150) {
            errors.region = "La localidad no debe exceder los 150 caracteres.";
        }
        if (operatorForm.city && operatorForm.city.length > 150) {
            errors.city = "La ciudad no debe exceder los 150 caracteres.";
        }
        if (operatorForm.commercial_name && operatorForm.commercial_name.length > 50) {
            errors.commercial_name = "El alias no debe exceder los 50 caracteres.";
        }
        if ((operatorForm.doc_type && !operatorForm.documentation) || (!operatorForm.doc_type && operatorForm.documentation)) {
            errors.documentation = "Si quiere poner una documentación, debe rellenar ambos campos (tipo y documentación).";
        }
        if (operatorForm.documentation && operatorForm.documentation.length > 15) {
            errors.documentation = "La documentación no debe exceder los 15 caracteres.";
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };


    const createOperator = async (event) => {

        let createError = null;

        try {
            event.preventDefault();
            const validationErrors = validateForm();
            if (validationErrors) {
                setError(validationErrors);
                return;
            }

            setLoading(true);

            const result = await api().post('/operators/', operatorForm);
            if (result.status < 300) {
                if (result.data != null && result.data.status_code >= 300) {
                    setError({ general: `Error: ${result.data.detail}` });
                } else {
                    showSnackbar("Operador de trnasporte creado correctamente");
                    navigate(`/operators/list`);
                    setError(null);
                }
            } else {
                createError = `Error ${result.status}: ${result.data.detail}`;
            }
        } catch (error) {
            createError = `Error inesperado creando el operador.`;
        } finally {
            setError(prevError => ({ ...prevError, general: createError }));
            setLoading(false);
        }
    };


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Operador
                </Typography>
                <br />
                <form onSubmit={createOperator}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Información Principal</Typography>
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={operatorForm.name}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 250 }}
                                error={!!error?.name}
                                helperText={error?.name}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={operatorForm.email}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.email}
                                helperText={error?.email}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Teléfono"
                                name="phone"
                                value={operatorForm.phone}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.phone}
                                helperText={error?.phone}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Nombre comercial (alias)"
                                name="commercial_name"
                                value={operatorForm.commercial_name}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 50 }}
                                error={!!error?.commercial_name}
                                helperText={error?.commercial_name}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Documentación</Typography>
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <FormControl
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="document-type-label">
                                    Documentación
                                </InputLabel>
                                <Select
                                    labelId="document-type-label"
                                    id="document-type"
                                    label='Tipo documentación'
                                    value={operatorForm.doc_type}
                                    onChange={handleChangeForm}
                                    name="doc_type"
                                    inputProps={{ id: 'document-type' }}
                                    error={!!error?.doc_type}
                                    helperText={error?.doc_type}
                                >
                                    <MenuItem value="DNI">DNI</MenuItem>
                                    <MenuItem value="NIF">NIF</MenuItem>
                                    <MenuItem value="NIE">NIE</MenuItem>
                                    <MenuItem value="CIF">CIF</MenuItem>
                                    <MenuItem value="OTRO">OTRO</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Documentación"
                                name="documentation"
                                value={operatorForm.documentation}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 15 }}
                                error={!!error?.documentation}
                                helperText={error?.documentation}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Dirección</Typography>
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="País"
                                name="country"
                                value={operatorForm.country}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.country}
                                helperText={error?.country}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Localidad"
                                name="region"
                                value={operatorForm.region}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 150 }}
                                error={!!error?.region}
                                helperText={error?.region}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Ciudad"
                                name="city"
                                value={operatorForm.city}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 150 }}
                                error={!!error?.city}
                                helperText={error?.city}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Código postal"
                                name="zip_code"
                                value={operatorForm.zip_code}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 40 }}
                                error={!!error?.zip_code}
                                helperText={error?.zip_code}
                            />
                        </Grid>
                        <Grid item xs={10} md={12}>
                            <TextField
                                fullWidth
                                label="Dirección"
                                name="address"
                                value={operatorForm.address}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 100 }}
                                error={!!error?.phone}
                                helperText={error?.phone}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error?.general && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error.general}
                    </Typography>
                )}
            </Paper >
        </div >
    );
}

export default NewOperator;
