import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { useSnackbar } from '../../components/SnackbarProvider';
import {
    DecimalTextField,
    formatPrice,
    formatDate,
    formatUnit,
    CreatePdf,
    handleApiError,
    DatePickerComponent,
    formatDayjs,
    DisableDialog
} from '../../components/Utils';
import {
    CircularProgress,
    Typography,
    Button,
    Card,
    CardContent,
    CardHeader,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Grid,
    TableContainer,
    Table,
    Paper,
    Divider
} from '@mui/material';


function InvoiceDetails() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [error, setError] = useState(null);
    const [invoiceData, setInvoiceData] = useState(null);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [invoiceLimitDate, setInvoiceLimitDate] = useState(null);
    const [invoiceDate, setInvoiceDate] = useState(null);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const fetchInvoice = useCallback(async () => {
        try {
            const result = await api().get(`/invoices/${id}`);
            const invoice = result.data;
            setInvoiceData(invoice);
            setInvoiceLimitDate(invoice.invoice_limit_date);
            setInvoiceDate(invoice.invoice_date);
            setLoading(false);
        } catch (error) {
            setError("Error obteniendo factura");
            setLoading(false);
        }
    }, [api, id]);

    useEffect(() => {
        fetchInvoice();
    }, []);

    const handlePayment = async () => {
        let error = null;
        try {
            setLoading(true);
            const endpoint = '/invoices/pay_invoice';
            const amount = paymentAmount;
            const updatedData = {
                invoice_id: id,
                amount: amount,
            };
            await api().patch(endpoint, updatedData);
            showSnackbar(`Pago de ${amount} € realizado con éxito.`);
            setPaymentAmount(0);
            fetchInvoice();
        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando pedido.')
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    const handleDateChange = async (name, newValue) => {
        let error = null;
        try {
            const formattedDate = formatDayjs(newValue);
            if (name === 'invoice_limit_date') {
                setInvoiceLimitDate(formattedDate);
            } else if (name === 'invoice_date') {
                setInvoiceDate(formattedDate);
            }
            setLoading(true);
            const updatedData = {
                invoice_id: id,
                [name]: formattedDate,
            };
            await api().patch(`/invoices/`, updatedData);
            showSnackbar(`Fecha cambiada correctamente.`);
            fetchInvoice();
        } catch (e) {
            error = handleApiError(e, 'Error inesperado cambiando fecha.')
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    const invoiceBase = parseFloat((invoiceData?.base_price || 0).toFixed(2));
    const total = parseFloat(((invoiceData?.total_price || 0)).toFixed(2));
    const vat = parseFloat(((invoiceData?.total_price || 0) - invoiceBase).toFixed(2));
    const remainingAmountToPay = invoiceData ? parseFloat((total - invoiceData.paid_amount).toFixed(2)) : 0;

    return (
        <div style={{ padding: 20 }}>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <Card sx={{ margin: 'auto' }}>
                    <CardHeader
                        title={(
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: '20px 0' }}>
                                <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                    {`Factura: ${invoiceData.code} | Fecha: ${formatDate(invoiceData.invoice_date) || '-'}`}
                                </span>

                                <Grid item xs={2}
                                    style={{
                                        position: 'absolute',
                                        right: '16px',
                                    }}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleOpenDisableDialog(id, '/invoices/disable', '/invoices/list')}
                                        style={{ backgroundColor: 'red', marginRight: '16px' }}
                                    >
                                        Eliminar
                                    </Button>
                                </Grid>
                            </div>
                        )}
                        sx={{
                            backgroundColor: '#f5f5f5',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            padding: '16px 0'
                        }}
                    />
                    {/* Diálogo de confirmación de eliminación */}
                    <DisableDialog
                        open={openDisableDialog}
                        onClose={() => setOpenDisableDialog(false)}
                        endpoint={endpointToDisable}
                        disableId={disableId}
                        navigateTo={navigateToWhenDisabled}
                    />
                    <CardContent>
                        <Grid container spacing={3}>
                            {/* Contenedor para pagar */}
                            <Grid item xs={12} md={6}>
                                <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                    <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                        Información de la Factura
                                    </Typography>

                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={12} md={12}>
                                            <Grid container spacing={2}>
                                                {/* Fecha de la factura */}
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant="body2" sx={{ fontSize: '14px', display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                                                        Fecha de la factura:
                                                    </Typography>

                                                    <DatePickerComponent
                                                        label="Fecha factura"
                                                        value={invoiceDate ? invoiceDate : null}
                                                        onChange={(newValue) => handleDateChange('invoice_date', newValue)}
                                                    />
                                                </Grid>

                                                {/* Fecha vencimiento de la factura */}
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant="body2" sx={{ fontSize: '14px', display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                                                        Fecha vencimiento de la factura:
                                                    </Typography>

                                                    <DatePickerComponent
                                                        label="Fecha vencimiento"
                                                        value={invoiceLimitDate ? invoiceLimitDate : null}
                                                        onChange={(newValue) => handleDateChange('invoice_limit_date', newValue)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* Total factura */}
                                        <Grid item xs={12} sm={4} sx={{ marginTop: '12px' }}>
                                            <Paper elevation={1} sx={{ padding: '10px', backgroundColor: '#ffffff', borderRadius: '8px' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px' }}>Total factura</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                                                    {formatPrice(total)}
                                                </Typography>
                                            </Paper>
                                        </Grid>

                                        {/* Pagado */}
                                        <Grid item xs={12} sm={4} sx={{ marginTop: '12px' }}>
                                            <Paper elevation={1} sx={{ padding: '10px', backgroundColor: '#e0ffe0', borderRadius: '8px' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px' }}>Pagado</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                                                    {formatPrice(invoiceData.paid_amount)}
                                                </Typography>
                                            </Paper>
                                        </Grid>

                                        {/* Pendiente */}
                                        <Grid item xs={12} sm={4} sx={{ marginTop: '12px' }}>
                                            <Paper elevation={1} sx={{ padding: '10px', backgroundColor: '#ffe0e0', borderRadius: '8px' }}>
                                                <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '14px' }}>Pendiente</Typography>
                                                <Typography variant="h6" sx={{ fontSize: '16px' }}>
                                                    {formatPrice(remainingAmountToPay)}
                                                </Typography>
                                            </Paper>
                                        </Grid>

                                        {/* Pago */}
                                        <Grid item xs={12} md={8}>
                                            <DecimalTextField
                                                label="Monto a Pagar"
                                                value={paymentAmount}
                                                onChange={(e) => setPaymentAmount(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                size="medium"
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={paymentAmount <= 0 || paymentAmount > remainingAmountToPay}
                                                onClick={() => handlePayment(false)}
                                                sx={{ height: '100%', minWidth: '150px' }}
                                            >
                                                Pagar Factura
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            {/* Información del Cliente o Proveedor */}
                            <Grid item xs={12} md={6}>
                                <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginBottom: '20px', textAlign: 'left' }}>
                                    <Typography variant="h6" sx={{ mb: 3, fontWeight: 'bold', fontSize: '16px' }}>
                                        Información del {invoiceData.client_id ? 'Cliente' : 'Proveedor'}
                                    </Typography>
                                    <Typography variant="body2" sx={{ fontSize: '14px', marginBottom: '10px' }}>
                                        {invoiceData.client_id ? (
                                            <>
                                                <strong>Código: </strong>
                                                <Link to={`/clients/${invoiceData.client.id}/details`}>
                                                    {invoiceData.client.code || '-'} <br />
                                                </Link>
                                                <strong>Nombre: </strong> {invoiceData.client.name || '-'} <br />
                                                <strong>Email: </strong> {invoiceData.client.email || '-'} <br />
                                                <strong>Teléfono: </strong> {invoiceData.client.phone || '-'} <br />
                                                <strong>Documentación: </strong> {invoiceData.client.documentation || '-'} <br />
                                            </>
                                        ) : (
                                            <>
                                                <strong>Código: </strong>
                                                <Link to={`/providers/${invoiceData.provider.id}/details`}>
                                                    {invoiceData.provider.code || '-'} <br />
                                                </Link>
                                                <strong>Nombre: </strong> {invoiceData.provider.name || '-'} <br />
                                                <strong>Email: </strong> {invoiceData.provider.email || '-'} <br />
                                                <strong>Teléfono: </strong> {invoiceData.provider.phone || '-'} <br />
                                                <strong>Documentación: </strong> {invoiceData.provider.documentation || '-'} <br />
                                            </>
                                        )}
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                        {/* Tabla de la Obra */}
                        <Paper elevation={3} sx={{ padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginTop: '20px' }}>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>
                                Obra: {invoiceData.building.name}
                            </Typography>

                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><strong>{'Albarán'}</strong></TableCell>
                                            <TableCell><strong>{'Artículo'}</strong></TableCell>
                                            <TableCell><strong>{'Precio Unitario'}</strong></TableCell>
                                            <TableCell><strong>{'Cantidad'}</strong></TableCell>
                                            <TableCell><strong>Total</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {invoiceData.delivery_notes.map((item) => {
                                            // Verificar la unidad del pedido
                                            const unit = item.order_detail?.unit || item.product.product_group.unit;

                                            // Calcular el neto según la unidad
                                            const net = unit === 'Tn' ? item.net / 1000 : item.net;

                                            // Calcular el precio total
                                            const unitPrice = item.order_detail?.unit_price || item.product.price
                                            const totalPrice = net * unitPrice;
                                            const productName = item.order_detail?.product_name || item.product.product_group.name

                                            return (
                                                <TableRow key={item.id}>
                                                    <TableCell>
                                                        <Link to={`/delivery_notes/${item.id}/details`}>
                                                            {item.code}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell>{productName}</TableCell>
                                                    <TableCell>{formatPrice(unitPrice)}</TableCell>
                                                    <TableCell>{net} {formatUnit(unit)}</TableCell>
                                                    <TableCell>{formatPrice(totalPrice)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {/* Información Adicional */}
                                        <TableRow>
                                            <TableCell rowSpan={4} colSpan={3}></TableCell>
                                            <TableCell><strong>Base imponible</strong></TableCell>
                                            <TableCell>{formatPrice(invoiceData.base_price)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>IVA ({invoiceData.vat}%)</strong></TableCell>
                                            <TableCell>{formatPrice(vat)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Total</strong></TableCell>
                                            <TableCell><strong>{formatPrice(invoiceData.total_price)}</strong></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        {/* Información adicional */}
                        <CardContent>
                            <Divider sx={{ my: 2 }} />
                            <div style={{ textAlign: 'right' }}>

                                <CreatePdf
                                    endpoint={`/invoices/${invoiceData.id}/generate-pdf/proform`}
                                    text="Ver proforma"
                                    sx={{ mr: 2 }}
                                />
                                <CreatePdf
                                    endpoint={`/invoices/${invoiceData.id}/generate-pdf/invoice`}
                                />

                            </div>
                        </CardContent>
                    </CardContent>
                </Card>
            )
            }
        </div >
    );
}

export default InvoiceDetails;
