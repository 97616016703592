import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth";
import { useSnackbar } from '../../components/SnackbarProvider';
import InvoiceTable from '../../components/InvoiceTable';
import {
    handleApiError,
    DisableDialog,
    Accordion,
    UploadDocumentButton,
    DownloadDocumentButton,
    formatOrderStatus,
    formatDate,
    formatPrice,
    formatUnit
} from '../../components/Utils';
import {
    TextField,
    Button,
    Typography,
    Paper,
    Grid,
    Box,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@mui/material';


function ClientDetails() {
    const { id } = useParams();
    const { api } = useAuth();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState({ general: true, update: false });
    const [error, setError] = useState({});

    const [invoices, setInvoices] = useState([]);
    const [totalPriceSum, setTotalPriceSum] = useState(null);
    const [paidAmountSum, setPaidAmountSum] = useState(null);
    const [remainingAmountSum, setRemainingAmountSum] = useState(null);

    const [openDisableDialog, setOpenDisableDialog] = useState(false);
    const [disableId, setDisableId] = useState(null);
    const [navigateToWhenDisabled, setNavigateToWhenDisabled] = useState(false);
    const [endpointToDisable, setEndpointToDisable] = useState(false);

    const [deliveryNotes, setDeliveryNotes] = useState({});

    const [clientForm, setClientForm] = useState({});
    const [buildings, setBuildings] = useState({});
    const [orders, setOrders] = useState({});

    const [openCreateFromClientDialog, setOpenCreateFromClientDialog] = useState(false);
    const [selectedAction, setSelectedAction] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fetchClient = useCallback(async () => {
        try {
            setLoading(prev => ({ ...prev, general: true }));
            const result = await api().get(`/clients/${id}`);
            const data = result.data;
            const client = result.data.client;

            setClientForm(client);
            setDeliveryNotes(data.delivery_notes);
            setBuildings(client.buildings);
            setOrders(client.orders);
            setInvoices(data.invoices);
            setTotalPriceSum(data.total_price_sum);
            setPaidAmountSum(data.paid_amount_sum);
            setRemainingAmountSum(data.remaining_amount_sum);

        } catch (exception_error) {
            setError(prevError => ({ ...prevError, fetch: "Error obteniendo cliente" }));

        } finally {
            setLoading(prev => ({ ...prev, general: false }));
        }
    }, [api, id]);

    useEffect(() => {
        fetchClient();
    }, []);

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setClientForm(prevClientForm => ({ ...prevClientForm, [name]: value }));
    };


    const validateForm = () => {
        const errors = {};

        if (!clientForm.name) {
            errors.name = "Por favor, añada un nombre.";
        } else if (clientForm.name.length > 250) {
            errors.name = "El nombre no debe exceder los 250 caracteres.";
        }
        if (clientForm.email && clientForm.email.length > 36) {
            errors.email = "El email no debe exceder los 36 caracteres.";
        }
        if (clientForm.phone && clientForm.phone.length > 36) {
            errors.phone = "El teléfono no debe exceder los 36 caracteres.";
        }
        if (clientForm.country && clientForm.country.length > 36) {
            errors.country = "El país no debe exceder los 36 caracteres.";
        }
        if (clientForm.zip_code && clientForm.zip_code.length > 40) {
            errors.zip_code = "El código postal no debe exceder los 40 caracteres.";
        }
        if (clientForm.region && clientForm.region.length > 150) {
            errors.region = "La localidad no debe exceder los 150 caracteres.";
        }
        if (clientForm.city && clientForm.city.length > 150) {
            errors.city = "La ciudad no debe exceder los 150 caracteres.";
        }
        if (clientForm.commercial_name && clientForm.commercial_name.length > 50) {
            errors.commercial_name = "El alias no debe exceder los 50 caracteres.";
        }
        if ((clientForm.doc_type && !clientForm.documentation) || (!clientForm.doc_type && clientForm.documentation)) {
            errors.documentation = "Si quiere poner una documentación, debe rellenar ambos campos (tipo y documentación).";
        }
        if (clientForm.documentation && clientForm.documentation.length > 15) {
            errors.documentation = "La documentación no debe exceder los 15 caracteres.";
        }
        if (clientForm.administrative_name && clientForm.administrative_name.length > 50) {
            errors.administrative_name = "El nombre administrativo no debe exceder los 50 caracteres.";
        }
        if (clientForm.administrative_phone && clientForm.administrative_phone.length > 36) {
            errors.administrative_phone = "El teléfono administrativo no debe exceder los 36 caracteres.";
        }
        if (clientForm.administrative_email && clientForm.administrative_email.length > 36) {
            errors.administrative_email = "El email administrativo no debe exceder los 36 caracteres.";
        }
        if (clientForm.administrative_position && clientForm.administrative_position.length > 36) {
            errors.administrative_position = "La posición administrativa no debe exceder los 36 caracteres.";
        }
        if (clientForm.sales_representative_name && clientForm.sales_representative_name.length > 50) {
            errors.sales_representative_name = "El nombre del representante de ventas no debe exceder los 50 caracteres.";
        }
        if (clientForm.sales_representative_phone && clientForm.sales_representative_phone.length > 36) {
            errors.sales_representative_phone = "El teléfono del representante de ventas no debe exceder los 36 caracteres.";
        }
        if (clientForm.sales_representative_email && clientForm.sales_representative_email.length > 36) {
            errors.sales_representative_email = "El email del representante de ventas no debe exceder los 36 caracteres.";
        }
        if (clientForm.sales_representative_position && clientForm.sales_representative_position.length > 36) {
            errors.sales_representative_position = "La posición del representante de ventas no debe exceder los 36 caracteres.";
        }

        return errors;
    };

    const handleUpdate = async (e) => {

        let error = null;

        try {
            e.preventDefault();
            setLoading(prev => ({ ...prev, update: true }));


            const validationError = validateForm();
            setError(validationError);

            const result = await api().patch(`/clients/`, clientForm);

            setClientForm(result.data);
            showSnackbar('Cliente actualizado correctamente');

        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando cliente.')

        } finally {
            setError(prevError => ({ ...prevError, update: error }));
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const handleOpenDisableDialog = (itemId, endpoint, navigateTo) => {
        setDisableId(itemId);
        setEndpointToDisable(endpoint);
        setNavigateToWhenDisabled(navigateTo);
        setOpenDisableDialog(true);
    };

    const handleCreateFromClient = async () => {
        if (!selectedAction) return;
        let error = null;
        setIsSubmitting(true);

        try {
            let endpoint = '';
            let message = '';
            if (selectedAction === 'transporter') {
                endpoint = `/transporters/create_from_client/${id}`;
                message = 'Transportista creado correctamente'
            } else if (selectedAction === 'operator') {
                endpoint = `/operators/create_from_client/${id}`;
                message = 'Op. Transporte creado correctamente'
            }

            await api().post(endpoint);

            showSnackbar(message);
        } catch (e) {
            error = handleApiError(e, 'Error inesperado actualizando cliente.')
        } finally {
            setError(prevError => ({ ...prevError, update: error }));
            setIsSubmitting(false);
            handleCloseCreateFromClientDialog();
        }
    };

    const handleOpenCreateFromClientDialog = (action) => {
        setSelectedAction(action);
        setOpenCreateFromClientDialog(true);
    };

    const handleCloseCreateFromClientDialog = () => {
        setOpenCreateFromClientDialog(false);
        setSelectedAction(null);
    };

    return (
        <div style={{ padding: 20 }}>
            {loading?.general && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                    <CircularProgress />
                </div>
            )}
            {!loading?.general && (
                <div>
                    <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                        <Grid item>
                            <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                                <Box mb={2}>
                                    <Typography variant="h5" component="h1" gutterBottom>
                                        {clientForm.code} - {clientForm.name}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={10} lg={8}>

                        {/* Client Accordion */}
                        <Accordion title={`${clientForm.code} ${clientForm.name}`}>
                            <form onSubmit={handleUpdate} style={{ padding: '20px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Información Principal</Typography>
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre"
                                            name="name"
                                            value={clientForm.name}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 250 }}
                                            error={!!error?.name}
                                            helperText={error?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            name="email"
                                            value={clientForm.email}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.email}
                                            helperText={error?.email}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Teléfono"
                                            name="phone"
                                            value={clientForm.phone}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.phone}
                                            helperText={error?.phone}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Nombre comercial (alias)"
                                            name="commercial_name"
                                            value={clientForm.commercial_name}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 50 }}
                                            error={!!error?.commercial_name}
                                            helperText={error?.commercial_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                                        <Typography variant="h6">Documentación</Typography>
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <FormControl
                                            style={{ width: "100%" }}
                                        >
                                            <InputLabel id="document-type-label">
                                                Documentación
                                            </InputLabel>
                                            <Select
                                                labelId="document-type-label"
                                                id="document-type"
                                                label='Tipo documentación'
                                                value={clientForm.doc_type}
                                                onChange={handleChangeForm}
                                                name="doc_type"
                                                inputProps={{ id: 'document-type' }}
                                                error={!!error?.doc_type}
                                                helperText={error?.doc_type}
                                            >
                                                <MenuItem value="DNI">DNI</MenuItem>
                                                <MenuItem value="NIF">NIF</MenuItem>
                                                <MenuItem value="NIE">NIE</MenuItem>
                                                <MenuItem value="CIF">CIF</MenuItem>
                                                <MenuItem value="OTRO">OTRO</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Documentación"
                                            name="documentation"
                                            value={clientForm.documentation}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 15 }}
                                            error={!!error?.documentation}
                                            helperText={error?.documentation}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                                        <Typography variant="h6">Dirección</Typography>
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="País"
                                            name="country"
                                            value={clientForm.country}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 36 }}
                                            error={!!error?.country}
                                            helperText={error?.country}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Localidad"
                                            name="region"
                                            value={clientForm.region}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 150 }}
                                            error={!!error?.region}
                                            helperText={error?.region}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Ciudad"
                                            name="city"
                                            value={clientForm.city}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 150 }}
                                            error={!!error?.city}
                                            helperText={error?.city}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={2}>
                                        <TextField
                                            fullWidth
                                            label="Código postal"
                                            name="zip_code"
                                            value={clientForm.zip_code}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 40 }}
                                            error={!!error?.zip_code}
                                            helperText={error?.zip_code}
                                        />
                                    </Grid>
                                    <Grid item xs={10} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Dirección"
                                            name="address"
                                            value={clientForm.address}
                                            onChange={handleChangeForm}
                                            inputProps={{ maxLength: 100 }}
                                            error={!!error?.phone}
                                            helperText={error?.phone}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: "20px" }}>
                                        <Typography variant="h6">Contactos</Typography>
                                    </Grid>
                                    <Grid container item spacing={2}>

                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Nombre Administrativo"
                                                name="administrative_name"
                                                value={clientForm.administrative_name}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 50 }}
                                                error={!!error?.administrative_name}
                                                helperText={error?.administrative_name}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Teléfono Administrativo"
                                                name="administrative_phone"
                                                value={clientForm.administrative_phone}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.administrative_phone}
                                                helperText={error?.administrative_phone}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Email Administrativo"
                                                name="administrative_email"
                                                value={clientForm.administrative_email}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.administrative_email}
                                                helperText={error?.administrative_email}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Posición Administrativo"
                                                name="administrative_position"
                                                value={clientForm.administrative_position}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.administrative_position}
                                                helperText={error?.administrative_position}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Nombre Representante de Ventas"
                                                name="sales_representative_name"
                                                value={clientForm.sales_representative_name}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 50 }}
                                                error={!!error?.sales_representative_name}
                                                helperText={error?.sales_representative_name}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Teléfono Representante de Ventas"
                                                name="sales_representative_phone"
                                                value={clientForm.sales_representative_phone}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.sales_representative_phone}
                                                helperText={error?.sales_representative_phone}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Email Representante de Ventas"
                                                name="sales_representative_email"
                                                value={clientForm.sales_representative_email}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.sales_representative_email}
                                                helperText={error?.sales_representative_email}
                                            />
                                        </Grid>
                                        <Grid item xs={10} md={2}>
                                            <TextField
                                                fullWidth
                                                label="Posición Representante de Ventas"
                                                name="sales_representative_position"
                                                value={clientForm.sales_representative_position}
                                                onChange={handleChangeForm}
                                                inputProps={{ maxLength: 36 }}
                                                error={!!error?.sales_representative_position}
                                                helperText={error?.sales_representative_position}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <br />
                                <Grid container spacing={2} style={{ marginTop: "20px" }}>
                                    <Grid item xs={3}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDisableDialog(id, '/clients/disable', '/clients/list')}
                                            fullWidth
                                            style={{ backgroundColor: 'red' }}>
                                            Eliminar
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading?.update}
                                            fullWidth>
                                            {loading?.update ? (
                                                <CircularProgress size={24} color="inherit" />
                                            ) : (
                                                'Actualizar'
                                            )}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            fullWidth
                                            onClick={() => handleOpenCreateFromClientDialog('transporter')}
                                        >
                                            Crear Transportista
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            fullWidth
                                            onClick={() => handleOpenCreateFromClientDialog('operator')}
                                        >
                                            Crear Operador
                                        </Button>
                                    </Grid>
                                    <Dialog
                                        open={openCreateFromClientDialog}
                                        onClose={handleCloseCreateFromClientDialog}
                                        aria-labelledby="confirm-dialog-title"
                                        aria-describedby="confirm-dialog-description"
                                    >
                                        <DialogTitle id="confirm-dialog-title">Confirmar Acción</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="confirm-dialog-description">
                                                {selectedAction === 'transporter'
                                                    ? '¿Estás seguro de que deseas crear un Transportista desde este cliente?'
                                                    : '¿Estás seguro de que deseas crear un Operador desde este cliente?'}
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleCloseCreateFromClientDialog} color="primary">
                                                Cancelar
                                            </Button>
                                            <Button
                                                onClick={handleCreateFromClient}
                                                color="success"
                                                disabled={isSubmitting} // Deshabilita mientras se envía
                                            >
                                                {isSubmitting ? (
                                                    <CircularProgress size={20} color="inherit" />
                                                ) : (
                                                    'Aceptar'
                                                )}
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Grid>
                                {error.update && (
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {error.update}
                                    </Typography>
                                )}
                            </form>
                        </Accordion>

                        {/* Documents Accordion */}
                        <Accordion title="Documentos">
                            <UploadDocumentButton
                                to={'client_id'}
                                id={id}
                                fetch={fetchClient}>
                            </UploadDocumentButton>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {clientForm.documents.filter(item => !item.disabled).map(item => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <DownloadDocumentButton documentId={item.id} documentName={item.name} />
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        size="small"
                                                        onClick={() => handleOpenDisableDialog(item.id, '/documents/disable')}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>

                        {/* Buildings Accordion */}
                        <Accordion title="Obras">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Nombre</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {buildings?.filter(building => !building.disabled).map(building => (
                                            <TableRow key={building.id}>
                                                <TableCell>
                                                    <Link to={`/buildings/${building.id}/details`}>
                                                        {building.code}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{building.name}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>

                        {/* Orders Accordion */}
                        <Accordion title="Pedidos">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Fecha de Pedido</TableCell>
                                            <TableCell>Estado</TableCell>
                                            <TableCell>Precio Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {orders?.filter(order => !order.disabled).map(order => (
                                            <TableRow key={order.id}>
                                                <TableCell>
                                                    <Link to={`/orders/${order.id}/details`}>
                                                        {order.code}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{formatDate(order.order_date)}</TableCell>
                                                <TableCell>{formatOrderStatus(order.order_status)}</TableCell>
                                                <TableCell>{formatPrice(order.total_price)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>

                        {/* Delivery Notes Accordion */}
                        <Accordion title="Albaranes">
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Código</TableCell>
                                            <TableCell>Fecha de Creación</TableCell>
                                            <TableCell>Neto</TableCell>
                                            <TableCell>Grupo de Producto</TableCell>
                                            <TableCell>Placa del Camión</TableCell>
                                            <TableCell>Nombre del Conductor</TableCell>
                                            <TableCell>Acciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {deliveryNotes?.filter(del_note => !del_note.disabled).map(del_note => (
                                            <TableRow key={del_note.id}>
                                                <TableCell>
                                                    <Link to={`/delivery_notes/${del_note.id}/details`}>
                                                        {del_note.code}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{formatDate(del_note.date_created)}</TableCell>
                                                <TableCell>{del_note.net} {formatUnit(del_note.product.product_group.unit)}</TableCell>
                                                <TableCell>
                                                    <Link to={`/products/${del_note.product.product_group.id}/details`}>
                                                        {del_note.product.product_group.name}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={`/trucks/${del_note.truck.id}/details`}>
                                                        {del_note.truck.license_plate || "-"}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={`/truck_drivers/${del_note.truck_driver.id}/details`}>
                                                        {del_note.truck_driver.name || "-"}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="secondary"
                                                        size="small"
                                                        onClick={() => handleOpenDisableDialog(del_note.id, '/delivery_notes/disable')}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Accordion>

                        {/* Invoices Accordion */}
                        <InvoiceTable
                            isProviderInvoice={false}
                            showBuilding={true}
                            showClientOrProvider={false}
                            title='Facturas'
                            totalAmount={totalPriceSum}
                            paidAmount={paidAmountSum}
                            remainingAmountSum={remainingAmountSum}
                            invoices={invoices}
                            uploadDocument={false}
                            fetch={fetchClient}
                            handleOpenDeleteDialog={handleOpenDisableDialog}
                        />

                        <DisableDialog
                            open={openDisableDialog}
                            onClose={() => setOpenDisableDialog(false)}
                            endpoint={endpointToDisable}
                            disableId={disableId}
                            navigateTo={navigateToWhenDisabled}
                            fetch={fetchClient}
                        />
                    </Grid>
                </div>)
            }
        </div >
    );
}

export default ClientDetails;
