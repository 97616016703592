import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import { useSnackbar } from '../../components/SnackbarProvider';
import { handleApiError } from '../../components/Utils';
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    FormControlLabel,
    Checkbox,
    CircularProgress
} from "@mui/material";

function NewTransporter() {

    const { api } = useAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const [transporterForm, setTransporterForm] = useState({
        name: null,
        email: null,
        phone: null,
        doc_type: null,
        documentation: null,
        country: null,
        zip_code: null,
        region: null,
        city: null,
        address: null,
        commercial_name: null,
        create_operator: false,
    });

    const handleChangeForm = (event) => {
        const { name, value } = event.target;
        setTransporterForm(prevState => ({
            ...prevState,
            [name]: value
        }));
        setError(prevError => ({ ...prevError, [name]: null }));
    };

    const validateForm = () => {
        const errors = {};

        if (!transporterForm.name) {
            errors.name = "Por favor, añada un nombre.";
        }
        if (transporterForm.name && transporterForm.name.length > 250) {
            errors.name = "El nombre no debe exceder los 250 caracteres.";
        }
        if (transporterForm.email && transporterForm.email.length > 36) {
            errors.email = "El email no debe exceder los 36 caracteres.";
        }
        if (transporterForm.country && transporterForm.country.length > 36) {
            errors.country = "El país no debe exceder los 36 caracteres.";
        }
        if (transporterForm.phone && transporterForm.phone.length > 36) {
            errors.phone = "El teléfono no debe exceder los 36 caracteres.";
        }
        if (transporterForm.zip_code && transporterForm.zip_code.length > 40) {
            errors.zip_code = "El código postal no debe exceder los 40 caracteres.";
        }
        if (transporterForm.province && transporterForm.province.length > 150) {
            errors.province = "La provincia no debe exceder los 150 caracteres.";
        }
        if (transporterForm.address && transporterForm.address.length > 100) {
            errors.address = "La dirección no debe exceder los 100 caracteres.";
        }
        if (transporterForm.region && transporterForm.region.length > 150) {
            errors.region = "La localidad no debe exceder los 150 caracteres.";
        }
        if (transporterForm.city && transporterForm.city.length > 150) {
            errors.city = "La ciudad no debe exceder los 150 caracteres.";
        }
        if (transporterForm.commercial_name && transporterForm.commercial_name.length > 50) {
            errors.commercial_name = "El alias no debe exceder los 50 caracteres.";
        }
        if ((transporterForm.doc_type && !transporterForm.documentation) || (!transporterForm.doc_type && transporterForm.documentation)) {
            errors.documentation = "Si quiere poner una documentación, debe rellenar ambos campos (tipo y documentación).";
        }
        if (transporterForm.documentation && transporterForm.documentation.length > 15) {
            errors.documentation = "La documentación no debe exceder los 15 caracteres.";
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };


    const createTransporter = async (event) => {

        let createError = null;

        try {
            event.preventDefault();
            const validationErrors = validateForm();
            if (validationErrors) {
                setError(validationErrors);
                return;
            }

            setLoading(true);

            await api().post('/transporters/', transporterForm);
            
            showSnackbar("Operador de transporte creado correctamente");
            navigate(`/transporters/list`);
            setError(null);

        } catch (e) {
            createError = handleApiError(e, 'Error inesperado creando transportista.')
        } finally {
            setError(prevError => ({ ...prevError, general: createError }));
            setLoading(false);
        }
    };


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Transportista
                </Typography>
                <br />
                <form onSubmit={createTransporter}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Información Principal</Typography>
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={transporterForm.name}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 250 }}
                                error={!!error?.name}
                                helperText={error?.name}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={transporterForm.email}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.email}
                                helperText={error?.email}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Teléfono"
                                name="phone"
                                value={transporterForm.phone}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.phone}
                                helperText={error?.phone}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Nombre comercial (alias)"
                                name="commercial_name"
                                value={transporterForm.commercial_name}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 50 }}
                                error={!!error?.commercial_name}
                                helperText={error?.commercial_name}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Documentación</Typography>
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <FormControl
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="document-type-label">
                                    Documentación
                                </InputLabel>
                                <Select
                                    labelId="document-type-label"
                                    id="document-type"
                                    label='Tipo documentación'
                                    value={transporterForm.doc_type}
                                    onChange={handleChangeForm}
                                    name="doc_type"
                                    inputProps={{ id: 'document-type' }}
                                    error={!!error?.doc_type}
                                    helperText={error?.doc_type}
                                >
                                    <MenuItem value="DNI">DNI</MenuItem>
                                    <MenuItem value="NIF">NIF</MenuItem>
                                    <MenuItem value="NIE">NIE</MenuItem>
                                    <MenuItem value="CIF">CIF</MenuItem>
                                    <MenuItem value="OTRO">OTRO</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Documentación"
                                name="documentation"
                                value={transporterForm.documentation}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 15 }}
                                error={!!error?.documentation}
                                helperText={error?.documentation}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Dirección</Typography>
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="País"
                                name="country"
                                value={transporterForm.country}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.country}
                                helperText={error?.country}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Localidad"
                                name="region"
                                value={transporterForm.region}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 150 }}
                                error={!!error?.region}
                                helperText={error?.region}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Ciudad"
                                name="city"
                                value={transporterForm.city}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 150 }}
                                error={!!error?.city}
                                helperText={error?.city}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Código postal"
                                name="zip_code"
                                value={transporterForm.zip_code}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 40 }}
                                error={!!error?.zip_code}
                                helperText={error?.zip_code}
                            />
                        </Grid>
                        <Grid item xs={10} md={12}>
                            <TextField
                                fullWidth
                                label="Dirección"
                                name="address"
                                value={transporterForm.address}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 100 }}
                                error={!!error?.phone}
                                helperText={error?.phone}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                            <Typography variant="h6">Duplicar</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="create_operator"
                                        checked={transporterForm.create_operator}
                                        onChange={(e) => setTransporterForm(prevState => ({ ...prevState, create_operator: e.target.checked }))}
                                    />}
                                label="Crear Operador"
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error?.general && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error.general}
                    </Typography>
                )}
            </Paper >
        </div >
    );
}

export default NewTransporter;
