import React, { useState, useEffect, useCallback } from 'react';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import {
    Typography,
    Table,
    TableBody,
    Paper,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    CardContent,
    Card,
} from '@mui/material';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import "dayjs/locale/es";
import utc from 'dayjs/plugin/utc';
import { useAuth } from "../pages/login/OAuth";
import Paginate from "./Paginate"
import { formatDate, itemsPerPage, formatPrice, formatText, formatInvoiceStatus } from "./Utils"

dayjs.extend(utc);

const InvoiceWithTotalsTable = ({
    title,
    isClient,
    code,
    status,
    externalProviderId,
    selectedBuilding,
    selectedClient,
    selectedProvider,
    selectedProductGroup,
    selectedQuarry,
    startInvoiceDate,
    endInvoiceDate,
    apply,
    reset
}) => {

    const { api } = useAuth();
    const [error, setError] = useState(null);

    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('ASC');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(null);

    const [invoices, setInvoices] = useState([]);

    const [totalPriceSum, setTotalPriceSum] = useState(null);
    const [paidAmountSum, setPaidAmountSum] = useState(null);
    const [remainingAmountSum, setRemainingAmountSum] = useState(null);
    const [totalOverdue, setTotalOverdue] = useState(null);

    const url = isClient ? 'invoices' : 'invoices_provider'
    const fetchInvoices = useCallback(async () => {
        try {
            const params = {};
            params.limit = itemsPerPage;
            params.offset = (currentPage - 1) * itemsPerPage;
            params.only_client_invoices = isClient;
            params.only_provider_invoices = !isClient;

            if (code) params.code = code;
            if (externalProviderId) params.external_provider_id = externalProviderId;
            if (status) params.invoice_status = status;
            if (selectedBuilding) params.building_id = selectedBuilding.id;
            if (selectedClient) params.client_id = selectedClient.id;
            if (selectedProvider) params.provider_id = selectedProvider.id;
            if (selectedProductGroup) params.product_group_id = selectedProductGroup.id;
            if (selectedQuarry) params.quarry_id = selectedQuarry.id;
            if (sortColumn) params.sort_by = sortColumn;
            if (sortDirection) params.sort_direction = sortDirection;
            if (startInvoiceDate) {
                const startDateMidday = dayjs(startInvoiceDate).hour(12).minute(0).second(0);
                params.start_invoice_date = startDateMidday.utc().format('YYYY-MM-DD');
            }
            if (endInvoiceDate) {
                const endDateMidday = dayjs(endInvoiceDate).hour(12).minute(0).second(0);
                params.end_invoice_date = endDateMidday.utc().format('YYYY-MM-DD');
            }

            const result = await api().get(`/${url}/`, { params });
            const data = result.data

            setInvoices(data.paginated_data);
            setTotalItems(data.data_length);
            setTotalPriceSum(data.total_price_sum);
            setPaidAmountSum(data.paid_amount_sum);
            setRemainingAmountSum(data.remaining_amount_sum);
            setTotalOverdue(data.total_invoice_overdue);

        } catch (error) {
            setError(`Error: ${JSON.stringify(error.response.data.detail)}`);
        }
    }, [api,
        currentPage,
        externalProviderId,
        startInvoiceDate,
        endInvoiceDate,
        code,
        status,
        selectedClient,
        isClient,
        selectedProductGroup,
        selectedQuarry,
        selectedBuilding,
        selectedProvider,
        sortColumn,
        sortDirection]);


    useEffect(() => {
        fetchInvoices();
    }, [currentPage, sortColumn, sortDirection]);

    useEffect(() => {
        if (apply) {
            setCurrentPage(1);
            fetchInvoices();
        }
    }, [apply]);

    useEffect(() => {
        if (reset) {
            setCurrentPage(1);
            fetchInvoices();
        }
    }, [reset]);

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortColumn(column);
            setSortDirection('ASC');
        }
        setCurrentPage(1);
    };

    const littleCellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '3%'
    };
    const mediumCellStyle = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '7%'
    };

    return (
        <div>
            {/* Title Display */}
            <Grid container spacing={3} justifyContent="flex-start" alignItems="center" sx={{ marginBottom: 3 }}>
                <Grid item xs={12}>
                    <Typography variant="h5" align="left" color="primary" sx={{ marginBottom: 2 }}>
                        {title}
                    </Typography>
                </Grid>
            </Grid>
            {/* Sums Display */}
            <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ marginBottom: 3 }}>
                <Grid item xs={12} sm={3}>
                    <Card elevation={3} sx={{ padding: 1.5 }}>
                        <CardContent>
                            <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                                Total Importe
                            </Typography>
                            <Typography variant="h6" align="center" color="primary">
                                {totalPriceSum ? totalPriceSum.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0,00€'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card elevation={3} sx={{ padding: 1.5 }}>
                        <CardContent>
                            <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                                Total Pagado
                            </Typography>
                            <Typography variant="h6" align="center" color="primary">
                                {paidAmountSum ? paidAmountSum.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0,00€'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card elevation={3} sx={{ padding: 1.5 }}>
                        <CardContent>
                            <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                                Total Restante
                            </Typography>
                            <Typography variant="h6" align="center" color="primary">
                                {remainingAmountSum ? remainingAmountSum.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0,00€'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Card elevation={3} sx={{ padding: 1.5 }}>
                        <CardContent>
                            <Typography variant="subtitle1" align="center" color="textSecondary" gutterBottom>
                                Total Vencido
                            </Typography>
                            <Typography variant="h6" align="center" color="primary">
                                {totalOverdue ? totalOverdue.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : '0,00€'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={littleCellStyle}>
                                <Typography variant="h6" gutterBottom>
                                    <span onClick={() => handleSort('code')} style={{ cursor: 'pointer' }}>
                                        Código {sortColumn === 'code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                    </span>
                                </Typography>
                            </TableCell>
                            {!isClient && (
                                <TableCell sx={littleCellStyle}>
                                    <Typography variant="h6" gutterBottom>
                                        <span onClick={() => handleSort('external_provider_id')} style={{ cursor: 'pointer' }}>
                                            Código externo {sortColumn === 'external_provider_id' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                        </span>
                                    </Typography>
                                </TableCell>
                            )}
                            <TableCell sx={littleCellStyle}>
                                <Typography variant="h6" gutterBottom>
                                    <span onClick={() => handleSort('invoice_date')} style={{ cursor: 'pointer' }}>
                                        Fecha {sortColumn === 'invoice_date' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                    </span>
                                </Typography>
                            </TableCell>
                            <TableCell sx={littleCellStyle}>
                                <Typography variant="h6" gutterBottom>
                                    <span onClick={() => handleSort('invoice_limit_date')} style={{ cursor: 'pointer' }}>
                                        Fecha vencimiento {sortColumn === 'invoice_limit_date' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                    </span>
                                </Typography>
                            </TableCell>
                            <TableCell sx={littleCellStyle}>
                                <Typography variant="h6" gutterBottom>
                                    Canteras
                                </Typography>
                            </TableCell>
                            <TableCell sx={littleCellStyle}>
                                <Typography variant="h6" gutterBottom>
                                    Productos
                                </Typography>
                            </TableCell>
                            {isClient && (
                                <TableCell sx={littleCellStyle}>
                                    <Typography variant="h6" gutterBottom>
                                        <span onClick={() => handleSort('building_code')} style={{ cursor: 'pointer' }}>
                                            Obra {sortColumn === 'building_code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                        </span>
                                    </Typography>
                                </TableCell>
                            )}
                            <TableCell sx={littleCellStyle}>
                                <Typography variant="h6" gutterBottom>
                                    {isClient ? (
                                        <span onClick={() => handleSort('client_code')} style={{ cursor: 'pointer' }}>
                                            Cliente {sortColumn === 'client_code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                        </span>
                                    ) : (
                                        <span onClick={() => handleSort('provider_code')} style={{ cursor: 'pointer' }}>
                                            Proveedor {sortColumn === 'provider_code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                        </span>
                                    )}
                                </Typography>
                            </TableCell>
                            <TableCell sx={littleCellStyle}>
                                <Typography variant="h6" gutterBottom>
                                    Estado
                                </Typography>
                            </TableCell>
                            <TableCell sx={mediumCellStyle}>
                                <Typography variant="h6" gutterBottom>
                                    <span onClick={() => handleSort('total_price')} style={{ cursor: 'pointer' }}>
                                        Importe {sortColumn === 'total_price' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                    </span>
                                </Typography>
                            </TableCell>

                            <TableCell sx={mediumCellStyle}>
                                <Typography variant="h6" gutterBottom>
                                    <span onClick={() => handleSort('paid_amount')} style={{ cursor: 'pointer' }}>
                                        Pagado {sortColumn === 'paid_amount' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                    </span>
                                </Typography>
                            </TableCell>
                            <TableCell sx={mediumCellStyle}>
                                <Typography variant="h6" gutterBottom>
                                    <span onClick={() => handleSort('remaining_to_pay')} style={{ cursor: 'pointer' }}>
                                        Restante {sortColumn === 'remaining_to_pay' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                    </span>
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((item) => {
                            const remainingAmount = item.total_price - (item.paid_amount ?? 0);
                            return (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row">
                                        <Link to={`/${url}/${item.id}/details`}>
                                            {item.code || '-'}
                                        </Link>
                                    </TableCell>
                                    {!isClient && (
                                        <TableCell component="th" scope="row">
                                            {formatText(item.external_provider_id)}
                                        </TableCell>
                                    )}
                                    <TableCell component="th" scope="row">
                                        {formatDate(item.invoice_date) || '-'}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {formatDate(item.invoice_limit_date) || '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item.quarries.map((quarry) => (
                                            <div key={quarry.id}>
                                                <Link to={`/quarries/${quarry.id}/details`}>
                                                    {quarry.code}
                                                </Link>
                                            </div>
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        {item.products.map((product) => (
                                            <div key={product.id}>
                                                <Link to={`/products/${product.product_group_id}/details`}>
                                                    {product.product_group_code}
                                                </Link>
                                            </div>
                                        ))}
                                    </TableCell>
                                    {isClient && (
                                        <TableCell>
                                            <Link to={`/buildings/${item.building.id}/details`}>
                                                {item.building.code || '-'}
                                            </Link>
                                        </TableCell>
                                    )}
                                    <TableCell component="th" scope="row">
                                        {isClient ? (
                                            <Link to={`/clients/${item.client.id}/details`}>
                                                {item.client.code || '-'}
                                            </Link>
                                        ) : (
                                            <Link to={`/providers/${item.provider.id}/details`}>
                                                {item.provider.code || '-'}
                                            </Link>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {formatInvoiceStatus(item.invoice_status)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {formatPrice(isClient ? item.total_price : -item.total_price)}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {formatPrice(isClient ? item.paid_amount : -item.paid_amount)}
                                    </TableCell>
                                    <TableCell>
                                        {formatPrice(isClient ? remainingAmount : -remainingAmount)}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <br />
            <Paginate
                totalItems={totalItems}
                onPageChange={setCurrentPage}
                currentPage={currentPage}
            />
        </div>
    )
}
export default InvoiceWithTotalsTable;