import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import { DecimalTextField, handleApiError, formatAutocompleteOption } from '../../components/Utils';
import { useSnackbar } from '../../components/SnackbarProvider';
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
    Autocomplete,
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    CircularProgress,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    IconButton,
    FormControlLabel,
    Switch
} from "@mui/material";

function NewProduct() {
    const { api } = useAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [quarries, setQuarries] = useState([]);

    const [quarriesStocks, setQuarriesStocks] = useState([]);

    const [productForm, setProductForm] = useState({
        name: '',
        unit: 'Tn',
        description: '',
        has_stock: false,
        show_CE_logo: false
    });

    const handleChangeForm = (event) => {
        const { name, value, type, checked } = event.target;
        setProductForm((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
        setError((prevError) => ({ ...prevError, [name]: null }));
    };

    const handleQuarriesStocksChange = (index, field, value) => {
        const updatedQuarriesStocks = [...quarriesStocks];
        updatedQuarriesStocks[index][field] = value;
        setQuarriesStocks(updatedQuarriesStocks);
        setError(prevError => ({ ...prevError, quarriesStocks: null }));
    };

    const addQuarryStock = () => {
        setQuarriesStocks([...quarriesStocks, { quarry: null, stock: '', price: '' }]);
    };

    const removeQuarryStock = (index) => {
        const updatedQuarriesStocks = quarriesStocks.filter((_, i) => i !== index);
        setQuarriesStocks(updatedQuarriesStocks);
    };

    useEffect(() => {
        fetchQuarries();
    }, []);

    useEffect(() => {
        if (productForm.has_stock) {
            setProductForm((prevState) => ({ ...prevState, unit: 'Tn' }));
        }
    }, [productForm.has_stock]);

    const fetchQuarries = useCallback(async () => {
        let fetchQuarryError = null;

        try {
            const result = await api().get('/quarries/');
            setQuarries(result.data[0]);
        } catch (error) {
            fetchQuarryError = 'Error obteniendo canteras.';
        } finally {
            setError(prevError => ({ ...prevError, general: fetchQuarryError }));
        }
    }, [api]);

    const validateForm = () => {
        const errors = {};

        // Validar nombre del producto
        if (!productForm.name) {
            errors.name = "Por favor, añada un nombre.";
        } else if (productForm.name.length > 36) {
            errors.name = "El nombre no debe exceder los 36 caracteres.";
        }

        // Validar unidad del producto
        if (!productForm.unit) {
            errors.unit = "Por favor, añada la unidad.";
        }

        if (productForm.has_stock) {
            // Si el usuario añadió un stock, validar que todos los campos de cada entrada estén completos
            if (quarriesStocks.length > 0) {
                quarriesStocks.forEach((q, index) => {
                    if (!q.quarry) {
                        errors[`quarry_${index}`] = "Por favor, seleccione una cantera.";
                    }
                    if (!q.stock) {
                        errors[`stock_${index}`] = "Por favor, añada el stock para esta cantera.";
                    }
                    if (!q.price) {
                        errors[`price_${index}`] = "Por favor, añada el precio para esta cantera.";
                    }
                });

                // Validar que no haya canteras duplicadas
                const selectedQuarries = quarriesStocks.map(q => q.quarry?.id);
                const uniqueQuarries = [...new Set(selectedQuarries)];
                if (uniqueQuarries.length !== selectedQuarries.length) {
                    errors.quarriesStocks = "No puede seleccionar la misma cantera más de una vez.";
                }
            }
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };


    const createProduct = async (event) => {
        let error = null;

        try {
            event.preventDefault();
            const validationErrors = validateForm();
            if (validationErrors) {
                setError(validationErrors);
                return;
            }

            setLoading(true);
        
            let newProduct = { ...productForm };
            
            if (productForm.has_stock) {
                const quarries_stocks = quarriesStocks.map(q => ({
                    quarry_id: q.quarry.id,
                    stock: parseFloat(q.stock),
                    price: parseFloat(q.price)
                }));
                newProduct.products = quarries_stocks;
            }

            await api().post('/products/', newProduct);

            showSnackbar("Producto creado correctamente");
            navigate(`/products/list`);
            setError(null);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando producto.');
        } finally {
            if (error) setError(prevError => ({ ...prevError, general: error }));
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '70%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo producto
                </Typography>
                <br />
                <form onSubmit={createProduct}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={productForm.name}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.name}
                                helperText={error?.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl style={{ width: "100%" }}>
                                <InputLabel id="unit-label">Unidades</InputLabel>
                                <Select
                                    labelId="unit-label"
                                    id="unit"
                                    label="Unidades"
                                    value={productForm.unit}
                                    onChange={handleChangeForm}
                                    name="unit"
                                    disabled={productForm.has_stock}
                                    error={!!error?.unit}
                                    helperText={error?.unit}
                                >
                                    <MenuItem value="Tn">Tn</MenuItem>
                                    <MenuItem value="kg">KG</MenuItem>
                                    <MenuItem value="uds">UDS</MenuItem>
                                    <MenuItem value="l">L</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Descripción"
                                name="description"
                                value={productForm.description}
                                onChange={handleChangeForm}
                                multiline
                                rows={2}
                                error={!!error?.description}
                                helperText={error?.description}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControlLabel
                                control={<Switch checked={productForm.show_CE_logo} onChange={handleChangeForm} name="show_CE_logo" />}
                                label="¿Mostrar logo CE?"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControlLabel
                                control={<Switch checked={productForm.has_stock} onChange={handleChangeForm} name="has_stock" />}
                                label="¿Producto con stock?"
                            />
                        </Grid>
                        {productForm.has_stock && (
                            <Grid item xs={12}>
                                <Typography variant="h6">Stocks por Cantera</Typography>
                                {quarriesStocks.length > 0 && quarriesStocks.map((quarryStock, index) => (
                                    <Box key={index} mb={2}>
                                        <Grid container spacing={2} alignItems="center">
                                            <Grid item xs={3}>
                                                <Autocomplete
                                                    options={quarries}
                                                    getOptionLabel={(option) => formatAutocompleteOption(option)}
                                                    value={quarryStock.quarry}
                                                    onChange={(event, newValue) => handleQuarriesStocksChange(index, 'quarry', newValue)}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Cantera"
                                                            error={!!error[`quarry_${index}`]}
                                                            helperText={error[`quarry_${index}`]}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DecimalTextField
                                                    label="Stock"
                                                    value={quarryStock.stock}
                                                    onChange={(e) => handleQuarriesStocksChange(index, 'stock', e.target.value)}
                                                    error={!!error[`stock_${index}`]}
                                                    helperText={error[`stock_${index}`]}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <DecimalTextField
                                                    label="Precio Cliente"
                                                    value={quarryStock.price}
                                                    onChange={(e) => handleQuarriesStocksChange(index, 'price', e.target.value)}
                                                    error={!!error[`price_${index}`]}
                                                    helperText={error[`price_${index}`]}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton
                                                    onClick={() => removeQuarryStock(index)}
                                                    disabled={quarriesStocks.length === 1}
                                                >
                                                    <RemoveCircleOutline />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}

                                <Button
                                    variant="outlined"
                                    onClick={addQuarryStock}
                                    startIcon={<AddCircleOutline />}
                                    style={{ marginTop: '10px' }}
                                >
                                    Añadir Stock en Cantera
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <br />
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error?.general && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error.general}
                    </Typography>
                )}
            </Paper>
        </div>
    );
}

export default NewProduct;
