import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import { handleApiError } from '../../components/Utils';
import { useSnackbar } from '../../components/SnackbarProvider';
import {
    Button,
    TextField,
    Grid,
    Box,
    Paper,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
} from "@mui/material";

function NewTruckDriver() {

    const { api } = useAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const [truckDriverForm, setTruckDriverForm] = useState({
        name: null,
        email: null,
        phone: null,
        doc_type: null,
        documentation: null,
        address: null,
    });

    const handleChangeForm = (event) => {
        const { name, value } = event.target;
        setTruckDriverForm(prevState => ({
            ...prevState,
            [name]: value
        }));
        setError(prevError => ({ ...prevError, [name]: null }));
    };

    const validateForm = () => {
        const errors = {};

        if (!truckDriverForm.name) {
            errors.name = "Por favor, añada un nombre.";
        }
        if (truckDriverForm.name && truckDriverForm.name.length > 250) {
            errors.name = "El nombre no debe exceder los 250 caracteres.";
        }
        if (truckDriverForm.email && truckDriverForm.email.length > 36) {
            errors.email = "El email no debe exceder los 36 caracteres.";
        }
        if (truckDriverForm.phone && truckDriverForm.phone.length > 36) {
            errors.phone = "El teléfono no debe exceder los 36 caracteres.";
        }
        if (truckDriverForm.address && truckDriverForm.address.length > 55) {
            errors.address = "La dirección no debe exceder los 55 caracteres.";
        }
        if ((truckDriverForm.doc_type && !truckDriverForm.documentation) || (!truckDriverForm.doc_type && truckDriverForm.documentation)) {
            errors.documentation = "Si quiere poner una documentación, debe rellenar ambos campos (tipo y documentación).";
        }
        if (truckDriverForm.documentation && truckDriverForm.documentation.length > 15) {
            errors.documentation = "La documentación no debe exceder los 15 caracteres.";
        }

        return Object.keys(errors).length > 0 ? errors : null;
    };


    const createTruckDriver = async (event) => {

        let error = null;

        try {
            event.preventDefault();
            const validationErrors = validateForm();
            if (validationErrors) {
                setError(validationErrors);
                return;
            }

            setLoading(true);

            await api().post('/truck_drivers/', truckDriverForm);

            showSnackbar("Camionero creado correctamente");
            setError(null);
            navigate(`/truck_drivers/list`);

        } catch (e) {
            error = handleApiError(e, 'Error inesperado creando camionero.')
        } finally {
            setError(prevError => ({ ...prevError, general: error }));
            setLoading(false);
        }
    };


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120%' }}>
            <Paper elevation={3} style={{ padding: 20, width: '40%' }}>
                <Typography component="h2" variant="h5">
                    Nuevo Camionero
                </Typography>
                <br />
                <form onSubmit={createTruckDriver}>
                    <Grid container spacing={2}>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={truckDriverForm.name}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 250 }}
                                error={!!error?.name}
                                helperText={error?.name}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="email"
                                value={truckDriverForm.email}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.email}
                                helperText={error?.email}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <FormControl
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="document-type-label">
                                    Documentación
                                </InputLabel>
                                <Select
                                    labelId="document-type-label"
                                    id="document-type"
                                    label='Tipo documentación'
                                    value={truckDriverForm.doc_type}
                                    onChange={handleChangeForm}
                                    name="doc_type"
                                    inputProps={{ id: 'document-type' }}
                                    error={!!error?.doc_type}
                                    helperText={error?.doc_type}
                                >
                                    <MenuItem value="DNI">DNI</MenuItem>
                                    <MenuItem value="NIF">NIF</MenuItem>
                                    <MenuItem value="NIE">NIE</MenuItem>
                                    <MenuItem value="CIF">CIF</MenuItem>
                                    <MenuItem value="OTRO">OTRO</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Documentación"
                                name="documentation"
                                value={truckDriverForm.documentation}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 15 }}
                                error={!!error?.documentation}
                                helperText={error?.documentation}
                            />
                        </Grid>
                        <Grid item xs={10} md={6}>
                            <TextField
                                fullWidth
                                label="Teléfono"
                                name="phone"
                                value={truckDriverForm.phone}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 36 }}
                                error={!!error?.phone}
                                helperText={error?.phone}
                            />
                        </Grid>
                        <Grid item xs={10} md={12}>
                            <TextField
                                fullWidth
                                label="Dirección"
                                name="address"
                                value={truckDriverForm.address}
                                onChange={handleChangeForm}
                                inputProps={{ maxLength: 55 }}
                                error={!!error?.phone}
                                helperText={error?.phone}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button type="submit" variant="contained" color="primary" disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'Crear'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
                {error?.general && (
                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                        {error.general}
                    </Typography>
                )}
            </Paper >
        </div >
    );
}

export default NewTruckDriver;
