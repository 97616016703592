import React, { useState } from 'react';
import { useSnackbar } from './SnackbarProvider';
import { Link } from 'react-router-dom';
import { useAuth } from "../pages/login/OAuth";
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Button
} from '@mui/material';
import {
    formatDate,
    CreatePdf,
    Accordion,
    UploadDocumentButton,
    handleApiError,
    formatPrice,
    formatInvoiceStatus,
    DownloadDocumentButton,
    formatText 
} from "./Utils"


const InvoiceTable = ({
    isProviderInvoice,
    showBuilding,
    showClientOrProvider,
    title,
    totalAmount,
    paidAmount,
    remainingAmountSum,
    invoices,
    fetch,
    uploadDocument,
    handleOpenDeleteDialog
}) => {
    const { api } = useAuth();
    const showSnackbar = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [invoicesAmounts, setInvoicesAmounts] = useState({});
    const [invoicesErrors, setInvoicesErrors] = useState({});

    const url = isProviderInvoice ? 'invoices_provider' : 'invoices';

    const handlePay = async (invoice_id) => {
        let error = null;
        try {
            setLoading(true);
            const endpoint = 'pay_invoice';
            const amounts = invoicesAmounts;
            const amountToPay = amounts[invoice_id];

            if (!amountToPay || amountToPay <= 0) {
                showSnackbar("Por favor, introduzca un valor válido.", 'error');
                return;
            }
            await api().patch(`/${url}/${endpoint}`, { invoice_id: invoice_id, amount: amountToPay });
            showSnackbar('Pago realizado correctamente');
            fetch();

        } catch (e) {
            error = handleApiError(e, 'Error inesperado pagando factura.')
        } finally {
            if (error) showSnackbar(error, 'error');
            setLoading(false);
        }
    };

    const handleAmountChange = (id, value) => {
        const invoicesList = invoices.filter(item => item.id === id)[0];
        let remainingAmount;
        let newErrors = {};

        remainingAmount = invoicesList.total_price - (invoicesList.paid_amount ?? 0) - (invoicesList.retention ?? 0);

        if (value <= 0) {
            newErrors[id] = "El monto debe ser mayor a 0.";
        } else if (value > remainingAmount) {
            newErrors[id] = `El monto no puede exceder el restante (${formatPrice(remainingAmount)})`;
        } else {
            delete newErrors[id];
        }

        setInvoicesAmounts(prev => ({
            ...prev,
            [id]: value
        }));
        setInvoicesErrors(newErrors);

    };

    // Determine table headers based on isInvoice
    const headers = [
        { label: 'Código', width: '5%' },
        ...(isProviderInvoice ? [{ label: 'Código externo', width: '5%' }] : []),
        { label: 'Fecha Creación', width: '2%' },
        { label: 'Fecha vencimiento', width: '2%' },
        ...(showBuilding ? [{ label: 'Obra', width: '8%' }] : []),
        ...(showClientOrProvider ? [{ label: isProviderInvoice ? 'Proveedor' : 'Cliente', width: '5%' }] : []),
        { label: 'Estado', width: '5%' },
        { label: 'Importe', width: '8%' },
        { label: 'Pagado', width: '8%' },
        { label: 'Restante', width: '6%' },
        { label: 'Cantidad a pagar', width: '8%' },
        { label: 'Acciones', width: '32%' }
    ];

    return (
        <Accordion title={title}>
            <Box sx={{
                display: 'flex', justifyContent: 'flex-start', alignItems: 'center',
                gap: '16px', padding: '8px 16px', marginBottom: '16px',
                backgroundColor: '#f5f5f5', borderRadius: '8px'
            }}>
                <Typography variant="h8" component="span">
                    <strong>Total Importe:</strong> {formatPrice(totalAmount)}
                </Typography>
                <Typography variant="h8" component="span">
                    <strong>Total Pagado:</strong> {formatPrice(paidAmount)}
                </Typography>
                <Typography variant="h8" component="span">
                    <strong>Total Restante:</strong> {formatPrice(remainingAmountSum)}
                </Typography>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell key={index} style={{ width: header.width }}>
                                    {header.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.filter(item => !item.disabled).map((item, index) => {
                            const remainingAmount = item.total_price - (item.paid_amount ?? 0);
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Link to={`/${url}/${item.id}/details`}>
                                            {item.code}
                                        </Link>
                                    </TableCell>
                                    {isProviderInvoice && (
                                        <TableCell>
                                            {formatText(item.external_provider_id)}
                                        </TableCell>
                                    )}
                                    <TableCell>{formatDate(item.invoice_date)}</TableCell>
                                    <TableCell>{formatDate(item.invoice_limit_date)}</TableCell>
                                    {showBuilding && (
                                        <TableCell>
                                            <Link to={`/buildings/${item.building.id}/details`}>
                                                {item.building.code}
                                            </Link>
                                        </TableCell>
                                    )}
                                    {showClientOrProvider && (
                                        <TableCell>
                                            <Link to={`/${isProviderInvoice ? 'providers' : 'clients'}/${item[isProviderInvoice ? 'provider' : 'client'].id}/details`}>
                                                {item[isProviderInvoice ? 'provider' : 'client'].code}
                                            </Link>
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        {formatInvoiceStatus(item.invoice_status)}
                                    </TableCell>
                                    <TableCell>
                                        {formatPrice(item.total_price)}
                                    </TableCell>
                                    <TableCell>
                                        {formatPrice(item.paid_amount)}
                                    </TableCell>
                                    <TableCell>
                                        {formatPrice(remainingAmount)}
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={invoicesAmounts[item.id]}
                                            onChange={(e) => handleAmountChange(item.id, parseFloat(e.target.value))}
                                            fullWidth
                                            error={!!(invoicesErrors[item.id])}
                                            helperText={invoicesErrors[item.id]}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handlePay(item.id)}
                                            sx={{ marginRight: '8px' }}
                                            disabled={remainingAmount <= 0 || !!(invoicesErrors[item.id]) || !(invoicesAmounts[item.id]) || loading}
                                        >
                                            Pagar
                                        </Button>
                                        {item.documents && item.documents.length > 0 && (() => {
                                            const activeDocument = item.documents.find(doc => !doc.disabled);
                                            return activeDocument ? (
                                                <DownloadDocumentButton documentId={activeDocument.id} documentName={activeDocument.name} />
                                            ) : null;
                                        })()}
                                        {uploadDocument ? (
                                            <UploadDocumentButton
                                                to={'invoice_provider_id'}
                                                id={item.id}
                                                fetch={fetch}
                                                showDisableDoc={item.documents && item.documents.length > 0}
                                            >
                                            </UploadDocumentButton>
                                        ) : (!isProviderInvoice) ? (
                                            <>
                                                <CreatePdf
                                                    endpoint={`/${url}/${item.id}/generate-pdf/proform`}
                                                    text="Ver proforma"
                                                    sx={{ marginRight: '8px' }}
                                                />
                                                <CreatePdf
                                                    endpoint={`/${url}/${item.id}/generate-pdf/invoice`}
                                                    sx={{ marginRight: '8px' }}
                                                />
                                            </>
                                        ) : null}
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleOpenDeleteDialog(item.id, `/${url}/disable`)}
                                        >
                                            Borrar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Accordion>
    );
};

export default InvoiceTable;
