import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import { useAuth } from "../login/OAuth"
import Grid from '@mui/material/Grid';
import "dayjs/locale/es";
import dayjs from 'dayjs';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import utc from 'dayjs/plugin/utc';
import {
    formatDate,
    formatTime,
    formatNumber,
    DatePickerComponent,
    CreateCSVFromList,
    CreatePdfFromList,
    CreateInvoice,
    formatAutocompleteOption
} from '../../components/Utils';
import {
    Table,
    Box,
    TableBody,
    TableCell,
    TableContainer,
    CircularProgress,
    TableHead,
    TableRow,
    Autocomplete,
    TextField,
    Paper,
    Checkbox,
    Button,
    Typography,
    FormControlLabel,
    Switch
} from "@mui/material";
dayjs.extend(utc);

function ListDeliveryNotes() {

    const { api } = useAuth();

    const [deliveryNotes, setDeliveryNotes] = useState([]);
    const [netSum, setNetSum] = useState(null);
    const [DNCounter, setDNCounter] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const [onlyInvoiced, setOnlyInvoiced] = useState(false);

    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('ASC');

    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setBuildingSelected] = useState(null);

    const [products, setProducts] = useState([]);
    const [selectedProduct, setProductSelected] = useState(null);

    const [clients, setClients] = useState([]);
    const [selectedClient, setClientSelected] = useState(null);

    const [deliveryNoteNumber, setDeliveryNoteNumber] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);

    const [selectedDeliveryNoteIds, setSelectedDeliveryNoteIds] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const [fetchReset, setFetchReset] = useState(false);

    const handleDeliveryNoteNumber = (event) => {
        setDeliveryNoteNumber(event.target.value);
    };

    const handleDateChange = (name, newValue) => {
        let date = dayjs(newValue).hour(12).minute(0).second(0);
        date = date.utc().format('YYYY-MM-DD');

        if (name === 'min_date') {
            setMinDate(date);
        } else if (name === 'max_date') {
            setMaxDate(date);
        }
    };

    const fetchDeliveryNotes = useCallback(async () => {

        try {
            setLoading(true);
            const params = {};
            if (!fetchReset) {
                if (sortColumn) params.sort_by = sortColumn;
                if (sortDirection) params.sort_direction = sortDirection;
                if (selectedBuilding) params.building_id = selectedBuilding.id;
                if (selectedClient) params.client_id = selectedClient.id;
                if (deliveryNoteNumber) params.code = deliveryNoteNumber;
                if (selectedProduct) params.product_group_id = selectedProduct.id;
                if (onlyInvoiced) params.only_invoiced = true;
                else params.only_invoiced = false;
                if (minDate) {
                    const startDateMidday = dayjs(minDate).hour(12).minute(0).second(0);
                    params.start_date = startDateMidday.utc().format('YYYY-MM-DD');
                }
                if (maxDate) {
                    const endDateMidday = dayjs(maxDate).hour(12).minute(0).second(0);
                    params.end_date = endDateMidday.utc().format('YYYY-MM-DD');
                }
            }
            else {
                setFetchReset(false);
            }
            const result = await api().get(`/delivery_notes/`, { params });
            setDeliveryNotes(result.data[0]);
            setNetSum(result.data[2]);
            setDNCounter(result.data[1]);
            setLoading(false);
        } catch (error) {
            setError(prevError => ({ ...prevError, general: 'Error obteniendo albaranes.' }));
        }
    }, [sortColumn, sortDirection, selectedBuilding, onlyInvoiced, selectedClient, selectedProduct, deliveryNoteNumber, minDate, maxDate, api]);

    const fetchBuildings = useCallback(async () => {
        try {
            const result = await api().get(`/buildings/`);
            setBuildings(result.data[0]);
        } catch (error) {
            setError('Error obteniendo obras.');
        }
    }, [api]);

    const fetchClients = useCallback(async () => {
        try {
            const result = await api().get(`/clients/`);
            setClients(result.data[0]);
        } catch (error) {
            setError('Error obteniendo obras.');
        }
    }, [api]);

    const fetchProducts = useCallback(async () => {

        try {
            const result = await api().get(`/products/`);
            setProducts(result.data[0]);
        } catch (error) {
            setError('Error obteniendo obras.');
        }
    }, [api]);

    useEffect(() => {
        fetchDeliveryNotes();
    }, [fetchReset, sortColumn, sortDirection]);

    useEffect(() => {
        fetchBuildings();
        fetchClients();
        fetchProducts();
    }, []);


    const handleChangeAutocomplete = (name, newValue) => {
        if (name === 'product_id') {
            setProductSelected(newValue);
        } else if (name === 'building_id') {
            setBuildingSelected(newValue);
        } else if (name === 'client_id') {
            setClientSelected(newValue);
        }
    };

    const handleApplyFilters = () => {
        fetchDeliveryNotes();
    };
    const handleResetFilters = () => {
        setBuildingSelected(null);
        setClientSelected(null);
        setProductSelected(null);
        setDeliveryNoteNumber(null);
        setMinDate(null);
        setMaxDate(null);
        setFetchReset(true);
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
        } else {
            setSortColumn(column);
            setSortDirection('ASC');
        }
    };

    const handleSelectAllCheckboxChange = () => {
        setSelectAllChecked(!selectAllChecked);
        if (!selectAllChecked) {
            setSelectedDeliveryNoteIds(deliveryNotes.map(item => item.id));
        } else {
            setSelectedDeliveryNoteIds([]);
        }
    };
    const handleCheckboxChange = (event, id) => {
        const stringId = id.toString();
        if (event.target.checked) {
            setSelectedDeliveryNoteIds([...selectedDeliveryNoteIds, stringId]);
        } else {
            setSelectedDeliveryNoteIds(selectedDeliveryNoteIds.filter(itemId => itemId !== stringId));
        }
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} container spacing={1}>
                <Grid item xs={12} sm={12} container spacing={1}>
                    <Grid item xs={6} sm={2}>
                        <Autocomplete
                            options={buildings}
                            getOptionLabel={(option) => formatAutocompleteOption(option)}
                            value={selectedBuilding}
                            onChange={(event, newValue) => handleChangeAutocomplete('building_id', newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Obras"
                                    style={{ maxWidth: '100%' }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <Autocomplete
                            options={clients}
                            getOptionLabel={(option) => formatAutocompleteOption(option)}
                            value={selectedClient}
                            onChange={(event, newValue) => handleChangeAutocomplete('client_id', newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Clientes"
                                    style={{ maxWidth: '100%' }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <Autocomplete
                            options={products}
                            getOptionLabel={(option) => formatAutocompleteOption(option)}
                            value={selectedProduct}
                            onChange={(event, newValue) => handleChangeAutocomplete('product_id', newValue)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Productos"
                                    style={{ maxWidth: '100%' }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <TextField
                            fullWidth
                            label="Número albarán"
                            name="num_delivery_note"
                            value={deliveryNoteNumber || ""}
                            onChange={handleDeliveryNoteNumber}
                        />
                    </Grid>
                    <Grid item xs={6} sm={2} style={{ marginTop: '7px', marginLeft: '8px' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={onlyInvoiced}
                                    onChange={(e) => setOnlyInvoiced(e.target.checked)}
                                    name="onlyInvoiced"
                                />
                            }
                            label="Solo Facturados"
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={6} container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <DatePickerComponent
                                label="Fecha Inicio"
                                value={minDate}
                                onChange={(newValue) => handleDateChange('min_date', newValue)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <DatePickerComponent
                                label="Fecha Fin"
                                value={maxDate}
                                onChange={(newValue) => handleDateChange('max_date', newValue)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} container spacing={2} justifyContent="flex-end">
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleApplyFilters}>
                                Aplicar Filtros
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={handleResetFilters}>
                                Borrar Filtros
                            </Button>
                        </Grid>
                    </Grid>
                    {deliveryNotes.length > 0 ? (
                        <>
                            {/* Alineamos los botones en una fila */}
                            <Box display="flex" alignItems="center" gap={2} sx={{ marginTop: '20px', marginLeft: '20px' }}>
                                <CreatePdfFromList
                                    endpoint="/delivery_notes/generate_pdf/list"
                                    list={selectedDeliveryNoteIds}
                                    text="Generar PDF"
                                />

                                <CreateCSVFromList
                                    endpoint="/delivery_notes/generate_csv/list"
                                    list={selectedDeliveryNoteIds}
                                    text="Generar CSV"
                                />

                                <CreateInvoice
                                    isProviderInvoice={false}
                                    deliveryNotes={selectedDeliveryNoteIds}
                                />

                                <Button
                                    onClick={handleSelectAllCheckboxChange}
                                    variant="contained"
                                    color="primary"
                                >
                                    {selectAllChecked ? "Desmarcar todos" : "Marcar todos"}
                                </Button>
                            </Box>

                            {/* Alineamos las dos tarjetas en la misma fila */}
                            <Box display="flex" gap={2} sx={{ marginTop: '20px', marginLeft: '20px' }}>
                                <Paper elevation={3} sx={{ padding: '10px', flexGrow: 1 }}>
                                    <Typography variant="h6">Neto total: {formatNumber(netSum)} kg</Typography>
                                </Paper>
                                <Paper elevation={3} sx={{ padding: '10px', flexGrow: 1 }}>
                                    <Typography variant="h6">Cantidad albaranes: {DNCounter}</Typography>
                                </Paper>
                            </Box>
                        </>
                    ) : (
                        <></>
                    )}

                </Grid>
            </Grid>
            <Grid item xs={12}>
                {loading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                    </div>
                )}
                {!loading && (
                    deliveryNotes.length > 0 ? (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>
                                                <span onClick={() => handleSort('code')} style={{ cursor: 'pointer' }}>
                                                    Nº Albarán {sortColumn === 'code' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </span>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>
                                                <span onClick={() => handleSort('departure_date')} style={{ cursor: 'pointer' }}>
                                                    Fecha {sortColumn === 'departure_date' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </span>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>
                                                <span onClick={() => handleSort('client_name')} style={{ cursor: 'pointer' }}>
                                                    Cliente {sortColumn === 'client_name' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </span>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>
                                                <span onClick={() => handleSort('building_name')} style={{ cursor: 'pointer' }}>
                                                    Obra {sortColumn === 'building_name' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </span>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>
                                                <span onClick={() => handleSort('product_name')} style={{ cursor: 'pointer' }}>
                                                    Producto {sortColumn === 'product_name' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </span>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>
                                                <span onClick={() => handleSort('truck_license_plate')} style={{ cursor: 'pointer' }}>
                                                    Matrícula camión {sortColumn === 'truck_license_plate' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </span>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>
                                                <span onClick={() => handleSort('trailer_license_plate')} style={{ cursor: 'pointer' }}>
                                                    Matrícula remolque {sortColumn === 'trailer_license_plate' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </span>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6" gutterBottom>
                                                <span onClick={() => handleSort('net')} style={{ cursor: 'pointer' }}>
                                                    Peso neto {sortColumn === 'net' && (sortDirection === 'ASC' ? <FaSortUp /> : <FaSortDown />)}
                                                </span>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {deliveryNotes.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedDeliveryNoteIds.includes(item.id)}
                                                    onChange={(event) => handleCheckboxChange(event, item.id)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Link to={`/delivery_notes/${item.id}/details`}>
                                                    {item.code}
                                                </Link>
                                            </TableCell>
                                            <TableCell>{formatDate(item.departure_date) + ' ' + formatTime(item.departure_date)}</TableCell>
                                            <TableCell>
                                                <Link to={`/clients/${item.client.id}/details`}>
                                                    {item.client.name || '-'}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <Link to={`/buildings/${item.building.id}/details`}>
                                                    {item.building.name || '-'}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <Link to={`/products/${item.product.product_group.id}/details`}>
                                                    {item.product.product_group.name || '-'}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <Link to={`/trucks/${item.truck.id}/details`}>
                                                    {item.truck.license_plate || '-'}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {item.trailer ? (
                                                    <Link to={`/trailers/${item.trailer.id}/details`}>
                                                        {item.trailer.license_plate || '-'}
                                                    </Link>
                                                ) : (
                                                    '-'
                                                )}
                                            </TableCell>
                                            <TableCell>{formatNumber(item.net) || '-'} Tn</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Grid item xs={12} container justifyContent="center" alignItems="center">
                            <Typography variant="body1">
                                No hay ningún albarán.
                            </Typography>
                        </Grid>
                    )
                )}
            </Grid>

            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                    {error}
                </Typography>
            )}
        </Grid>
    );
}
export default ListDeliveryNotes;